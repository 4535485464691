/**
 * This sets up a very lightweight App State based on React Context API
 * with a familiar reducer / actions / dispatcher syntax, and also exposes
 * this to Redux Devtools which is good and not bad.
 * More info here: https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c
 * ...and here: https://github.com/troch/reinspect
 */
import React, { createContext, useContext } from 'react';
import { useReducer } from 'reinspect';
import { updateFire } from './../lib/firestarter';

export const configuredInitState = {
  firebaseConfig: undefined,
  quiz: undefined,
  active: false,
  currentPage: 0,
  feedback: null,
  modal: null,
  menuOpen: false,
  score: 0,
  fullScreenRef: null,
  customBackground: null,
  formKey: null,
  notCheated: true,
  quizName: 'Unknown',
  itsChristmas: false,
  showQuizURLs: false,
};

export const configuredReducer = (state, action) => {
  switch (action.type) {
    case 'setActive': {
      return {
        ...state,
        active: action.payload,
      };
    }
    case 'setFirebaseConfig': {
      return {
        ...state,
        firebaseConfig: action.payload,
      };
    }
    case 'resetQuiz': {
      return {
        ...state,
        currentPage: 0,
        feedback: null,
        modal: null,
        score: 0,
      };
    }
    case 'bumpScore': {
      return {
        ...state,
        score: state.score + 1,
      };
    }
    case 'formKey': {
      return {
        ...state,
        formKey: action.payload,
      };
    }
    case 'notCheated': {
      // Set cheated to true
      updateFire(state.firebaseConfig, { cheated: true });
      return {
        ...state,
        notCheated: action.payload,
      };
    }
    case 'nerfScore': {
      return {
        ...state,
        score: state.score - 1 >= 0 ? state.score - 1 : 0,
      };
    }
    case 'setScore': {
      return {
        ...state,
        score: action.payload,
      };
    }
    case 'setQuiz': {
      return {
        ...state,
        quiz: action.payload,
      };
    }
    case 'setShowModal': {
      return {
        ...state,
        modal: action.payload,
        menuOpen: false,
      };
    }
    case 'setMenuOpen': {
      return {
        ...state,
        menuOpen: action.payload,
      };
    }
    case 'setFeedback': {
      return {
        ...state,
        feedback: action.payload,
      };
    }
    case 'setCurrentPage': {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case 'moveForward': {
      const pages = [
        ...(state.quiz?.prePages ?? []),
        ...(state.quiz?.questions ?? []),
        ...(state.quiz?.postPages ?? []),
      ];
      const theLastAction = {
        // TODO: Activate this when starting the next quiz
        // lastAction: { type: pages[state.currentPage]._type, index: state.currentPage + 1 },
        lastAction: { type: pages[state.currentPage]._type, index: state.currentPage },
      };
      updateFire(state.firebaseConfig, theLastAction);
      updateFire(state.firebaseConfig, { sessionEnd: new Date().getTime() });

      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    }
    case 'moveBack': {
      return {
        ...state,
        currentPage: state.currentPage ? state.currentPage - 1 : 0,
      };
    }
    case 'setFullScreenRef': {
      return {
        ...state,
        fullScreenRef: action.payload,
      };
    }
    case 'setCustomBG': {
      return {
        ...state,
        customBackground: action.payload,
      };
    }
    case 'setQuizName': {
      return {
        ...state,
        quizName: action.payload,
      };
    }
    case 'setItsChristmas': {
      return {
        ...state,
        itsChristmas: action.payload,
      };
    }
    case 'setShowQuizURLs': {
      return {
        ...state,
        showQuizURLs: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const StateContext = createContext([[], () => {}]);
export const StateProvider = ({ children, reducer, initState }) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initState, undefined, 'app-reducer')}>
      {children}
    </StateContext.Provider>
  );
};
export const useStateValue = () => useContext(StateContext);
