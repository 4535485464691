import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';

initializeApp({
  apiKey: 'AIzaSyAsREP0FmXuYIy6MgcfwFal8w3thkShQFQ',
  authDomain: 'engage-db.firebaseapp.com',
  projectId: 'engage-db',
  storageBucket: 'engage-db.appspot.com',
  messagingSenderId: '810114960280',
  appId: '1:810114960280:web:f607b9ab6490bf55aa79ed',
  measurementId: 'G-2NQKGTVVTL',
});

const db = getFirestore();

const formNode = 'form_data';
const sessionNode = 'sessions';

export async function createFire(collectionId, initials, typeOfData) {
  /* addDoc will create a new doc with an arbitrary ID which we can
  pick up from the return value and use for later. */
  // console.log(collectionId);

  if (typeOfData === 'formData') {
    try {
      // const docRef = await addDoc(collection(db, formNode, collectionId, 'entries'), initials);
      // All in one pile
      const docRef = await addDoc(collection(db, formNode), initials);
      // const msg = `Document written with ID: ${docRef.id}`;
      // console.log('msg: ', msg, initials);

      return docRef.id;
    } catch (err) {
      console.error('Error adding document: ', err);
      return err;
    }
  } else if (typeOfData === 'sessionData') {
    try {
      const docRef = await addDoc(
        // collection(db, sessionNode, collectionId, sessionsName),
        collection(db, 'sessions'),
        initials,
      );
      const msg = `Document written with ID: ${docRef.id}`;
      // console.log('msg: ', msg);
      return docRef.id;
    } catch (err) {
      console.error('Error adding document: ', err);
      return err;
    }
  } else {
    console.log('Error: typeOfData not recognized');
    return 'Error: typeOfData not recognized';
  }
}

// deleteFire - where dev mode has you deleting the node.

export async function updateFire({ node, id }, data) {
  // We could have a switch where the update only happens if not cheat is enabled

  // console.log('Updated fire', { node, id, data });
  try {
    const quizRef = doc(db, sessionNode, id);
    // const quizRef = doc(db, sessionNode, node, sessionsName, id);
    setDoc(quizRef, data, { merge: true });
  } catch (err) {
    console.log('There is an error');
    console.error('Error', err);
  }
}
